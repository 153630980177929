// Libraries
import React from 'react';

// Supermove
import {Styled, Icon} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {
  useResponsive,
  ResponsiveType,
  useDrawer,
  useModal,
  useToast,
  ToastType,
  MutationError,
} from '@supermove/hooks';
import {User, UserModel, TeamModel, OrganizationModel} from '@supermove/models';
import {colors} from '@supermove/styles';
import {Phone} from '@supermove/utils';

// App
import Table from '@shared/design/components/Table';
import {ColumnDefinitionType} from '@shared/design/components/Table/components/TableBuilder';
import ErrorToast from '@shared/design/components/Toast/ErrorToast';
import UserRole from '@shared/modules/User/enums/UserRole';
import UserStatus from '@shared/modules/User/enums/UserStatus';
import ImpersonateUserForm from '@shared/modules/User/forms/ImpersonateUserForm';
import useImpersonateUserMutation from '@shared/modules/User/hooks/useImpersonateUserMutation';
import StaffCommunicationDrawer from 'modules/Communication/components/StaffCommunicationDrawer';
import {
  SelectedUserStatusType,
  TEAMS,
} from 'modules/Organization/Settings/Staff/OrganizationSettingsStaffOfficeStaffContentV2';
import OfficeStaffCommunicationActionDrawer from 'modules/Organization/Settings/Staff/components/OfficeStaffCommunicationActionDrawer';
import OfficeStaffReinviteUserModal from 'modules/Organization/Settings/Staff/components/OfficeStaffReinviteUserModal';
import TeamsTable from 'modules/Organization/Settings/Staff/components/TeamsTable';
import DeleteUserModal from 'modules/User/components/DeleteUserModal';
import UpdateUserDrawer from 'modules/User/components/UpdateUserDrawer';

const TeamsTableContainer = Styled.View<{responsive: ResponsiveType}>`
  flex: 1;
  padding-horizontal: ${({responsive}) => (responsive.desktop ? 0 : 16)}px;
`;

const getUsersTableColumnDefinition = ({
  viewer,
  organization,
  refetch,
  isEnabledConnectedEmail,
  impersonateErrorToast,
}: {
  viewer: UserModel;
  organization: OrganizationModel;
  refetch: () => void;
  isEnabledConnectedEmail: boolean;
  impersonateErrorToast: ToastType;
}): ColumnDefinitionType<UserModel>[] => [
  {
    flex: 1,
    headerLabel: 'Name',
    cellText: (officeUser) => officeUser.fullName,
    mobileOptions: {
      isInHeader: true,
      rank: 0,
    },
  },
  {
    flex: 1,
    headerLabel: 'Login Email',
    cellText: (officeUser) => officeUser.email,
    ...(isEnabledConnectedEmail
      ? {
          secondary: {
            headerLabel: 'Connected Email',
            cellText: (officeUser) => officeUser.activeAccountGrant?.identifier,
            emptyText: 'Not connected',
          },
        }
      : {}),
    mobileOptions: {
      rank: 1,
    },
  },
  {
    flex: 1,
    headerLabel: 'Phone Number',
    cellText: (officeUser) => Phone.display(officeUser.phoneNumber),
    mobileOptions: {
      rank: 2,
    },
  },
  {
    flex: 1,
    headerLabel: 'Position',
    cellText: (officeUser) => User.getPosition(officeUser),
    mobileOptions: {
      rank: 3,
    },
  },
  {
    flex: 1,
    headerLabel: 'Role',
    cellText: (officeUser) => UserRole.getDisplayRole(officeUser.role),
    mobileOptions: {
      rank: 4,
    },
  },
  {
    minWidth: 80,
    // @ts-expect-error TS(2322): Type '(officeUser: UserModel) => { text: string; onPress:...
    actions: (officeUser) => [
      {
        text: 'Communication',
        isHidden: !UserRole.hasOfficePermissions(viewer.role),
        onPress: ({handleOpen}: {handleOpen: () => void}) => handleOpen(),
        desktopIcon: Icon.CommentDots,
        actionHook: {
          hook: useDrawer,
          hookArgument: {
            name: `Staff Communication Drawer`,
          },
          renderComponent: ({
            isOpen,
            handleClose,
            hookKey,
          }: {
            isOpen: boolean;
            handleClose: () => void;
            hookKey: string;
          }) => {
            return (
              <OfficeStaffCommunicationActionDrawer
                key={hookKey}
                officeUser={officeUser}
                refetch={refetch}
                viewer={viewer}
                isOpen={isOpen}
                handleClose={handleClose}
              />
            );
          },
        },
      },
      {
        text: 'Resend invitation',
        isHidden: !(
          UserRole.hasAdminPermissions(viewer.role) && officeUser.status === UserStatus.PENDING
        ),
        onPress: ({handleOpen}: {handleOpen: () => void}) => handleOpen(),
        actionHook: {
          hook: useModal,
          hookArgument: {name: 'ConfirmReinviteUserModal'},
          renderComponent: ({
            isOpen,
            handleClose,
            hookKey,
          }: {
            isOpen: boolean;
            handleClose: () => void;
            hookKey: string;
          }) => {
            return (
              <OfficeStaffReinviteUserModal
                key={hookKey}
                user={officeUser}
                organization={organization}
                isOpen={isOpen}
                handleClose={handleClose}
                refetch={refetch}
              />
            );
          },
        },
      },
      {
        text: 'Edit user',
        isHidden: !UserRole.hasAdminPermissions(viewer.role),
        onPress: ({handleOpen}: {handleOpen: () => void}) => handleOpen(),
        actionHook: {
          hook: useDrawer,
          hookArgument: {name: 'Update User Drawer'},
          renderComponent: ({
            isOpen,
            handleClose,
            hookKey,
          }: {
            isOpen: boolean;
            handleClose: () => void;
            hookKey: string;
          }) => {
            return (
              <UpdateUserDrawer
                key={hookKey}
                user={officeUser}
                isOpen={isOpen}
                handleClose={handleClose}
                handleSuccess={() => {
                  handleClose();
                  refetch();
                }}
                viewerRole={viewer.role}
              />
            );
          },
        },
      },
      {
        text: 'Impersonate user',
        isHidden: viewer.role !== UserRole.SUPER_ADMIN,
        onPress: ({handleSubmit}: {handleSubmit: () => void}) => handleSubmit(),
        actionHook: {
          hook: useImpersonateUserMutation,
          hookArgument: {
            impersonateUserForm: ImpersonateUserForm.new({userId: officeUser.id}),
            onSuccess: () => {
              // TODO(Hammad) remove any type
              window.location = '/' as any;
            },
            onError: (errors: MutationError[]) => {
              impersonateErrorToast.handleToast();
              console.log({errors});
            },
          },
        },
      },
      {
        text: 'Delete user',
        color: colors.red.warning,
        isHidden: !UserRole.hasAdminPermissions(viewer.role),
        onPress: ({handleOpen}: {handleOpen: () => void}) => handleOpen(),
        actionHook: {
          hook: useModal,
          hookArgument: {name: 'DeleteUserModal'},
          renderComponent: ({
            isOpen,
            handleClose,
            hookKey,
          }: {
            isOpen: boolean;
            handleClose: () => void;
            hookKey: string;
          }) => {
            return (
              <DeleteUserModal
                key={hookKey}
                isOpen={isOpen}
                handleClose={handleClose}
                userId={officeUser.id}
                refetch={refetch}
                userFullName={officeUser.fullName}
              />
            );
          },
        },
      },
    ],
  },
];

const OfficeStaffUsersTable = ({
  selectedUserStatus,
  officeUsers,
  officeTeams,
  organization,
  isEnabledConnectedEmail,
  refetch,
  viewer,
}: {
  selectedUserStatus: SelectedUserStatusType;
  officeUsers: UserModel[];
  officeTeams: TeamModel[];
  organization: OrganizationModel;
  isEnabledConnectedEmail: boolean;
  refetch: () => void;
  viewer: UserModel;
}) => {
  const responsive = useResponsive();
  const impersonateErrorToast = useToast({
    ToastComponent: ErrorToast,
    message: `Unable to impersonate user.`,
  });

  switch (selectedUserStatus) {
    case UserStatus.ACTIVE:
    case UserStatus.PENDING:
    case UserStatus.INACTIVE:
      return (
        <React.Fragment>
          <Table
            itemKey={'uuid'}
            columnDefinitions={getUsersTableColumnDefinition({
              viewer,
              organization,
              refetch,
              isEnabledConnectedEmail,
              impersonateErrorToast,
            })}
            emptyStateText={'No office staff to display'}
            items={officeUsers}
            isScrollable
          />
        </React.Fragment>
      );
    case TEAMS:
      return (
        <TeamsTableContainer responsive={responsive}>
          <TeamsTable teams={officeTeams} refetch={refetch} viewer={viewer} />
        </TeamsTableContainer>
      );
    default:
      return null;
  }
};

// --------------------------------------------------
// Data
// --------------------------------------------------
OfficeStaffUsersTable.fragment = gql`
  ${StaffCommunicationDrawer.fragment}
  ${UpdateUserDrawer.fragment}
  ${OfficeStaffCommunicationActionDrawer.fragment}
  ${OfficeStaffReinviteUserModal.fragment}

  fragment OfficeStaffUsersTable on User {
    id
    uuid
    fullName
    email
    phoneNumber
    position
    role
    status
    activeAccountGrant {
      id
      identifier
    }
    ...StaffCommunicationDrawer
    ...UpdateUserDrawer
    ...OfficeStaffCommunicationActionDrawer
    ...OfficeStaffReinviteUserModal
  }
`;

export default OfficeStaffUsersTable;
