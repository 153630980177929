// Libraries
import React from 'react';

// Supermove
import {Styled} from '@supermove/components';
import {gql, useQuery} from '@supermove/graphql';
import {useResponsive} from '@supermove/hooks';
import {AccountGrant, UserModel} from '@supermove/models';
import {AccountGrantInfoType} from '@supermove/models/src/AccountGrant';
import {colors} from '@supermove/styles';

// App
import Text from '@shared/design/components/Text';
import Banner from 'modules/App/components/Banner';

// Show the banner if there are 14 days or less until the account grant expires
const DAYS_UNTIL_EXPIRATION_THRESHOLD = 14;

const Container = Styled.View`
  padding-horizontal: 16px;
`;

type BannerType = 'warning' | 'error';
const ActionNeededBanner = ({
  text,
  onPress,
  type,
}: {
  text: string;
  onPress: () => void;
  type: BannerType;
}) => {
  const responsive = useResponsive();
  const colorsInfo: Record<BannerType, {backgroundColor: string; textColor: string}> = {
    warning: {
      backgroundColor: colors.orange.accent,
      textColor: colors.orange.status,
    },
    error: {
      backgroundColor: colors.red.accent,
      textColor: colors.red.warning,
    },
  };
  const {backgroundColor} = colorsInfo[type];

  return (
    <Container data-no-print style={{backgroundColor}}>
      <Banner color={backgroundColor} responsive={responsive}>
        <Text.Label style={{textAlign: 'center'}}>
          <Text.Label style={{color: colorsInfo[type].textColor}}>{text} </Text.Label>
          <Text.Link onPress={onPress}>Click to reconnect</Text.Link>
        </Text.Label>
      </Banner>
    </Container>
  );
};

type BannerToShow =
  | 'COMPANY_EXPIRED'
  | 'USER_EXPIRED'
  | 'COMPANY_EXPIRING'
  | 'USER_EXPIRING'
  | 'NONE';

const getBannerToShow = (accountGrantInfo: AccountGrantInfoType): BannerToShow => {
  if (accountGrantInfo.hasExpiredCompanyAccount && accountGrantInfo.hasEditPermissions) {
    return 'COMPANY_EXPIRED';
  }
  if (accountGrantInfo.hasExpiredUserAccount) {
    return 'USER_EXPIRED';
  }
  if (
    accountGrantInfo.hasEditPermissions &&
    accountGrantInfo.companyAccountDaysUntilExpiration &&
    accountGrantInfo.companyAccountDaysUntilExpiration <= DAYS_UNTIL_EXPIRATION_THRESHOLD
  ) {
    return 'COMPANY_EXPIRING';
  }
  if (
    accountGrantInfo.userAccountDaysUntilExpiration &&
    accountGrantInfo.userAccountDaysUntilExpiration <= DAYS_UNTIL_EXPIRATION_THRESHOLD
  ) {
    return 'USER_EXPIRING';
  }
  return 'NONE';
};

const AccountGrantActionNeededContent = ({viewer}: {viewer: UserModel}) => {
  const accountGrantInfo = AccountGrant.accountGrantInfo(viewer);
  const {userAccountDaysUntilExpiration, companyAccountDaysUntilExpiration} = accountGrantInfo;
  const bannerToShow = getBannerToShow(accountGrantInfo);
  const isCompanyAccount = ['COMPANY_EXPIRED', 'COMPANY_EXPIRING'].includes(bannerToShow);

  const connectionRedirectPath = isCompanyAccount
    ? '/settings/communication/general'
    : '/account/communication/email';

  const handleConnectAccount = () => {
    const redirectUrl = new URL(
      connectionRedirectPath,
      `${window.location.protocol}//${window.location.host}`,
    );
    AccountGrant.navigateToNylasOauthUrl(redirectUrl.toString());
  };

  if (bannerToShow === 'COMPANY_EXPIRED') {
    return (
      <ActionNeededBanner
        text={
          'The company email is disconnected. Reconnect the email account to continue sending emails with the company email address.'
        }
        onPress={handleConnectAccount}
        type='error'
      />
    );
  }

  if (bannerToShow === 'USER_EXPIRED') {
    return (
      <ActionNeededBanner
        text={
          'Your email is disconnected. Reconnect your email account to continue sending emails with your email address.'
        }
        onPress={handleConnectAccount}
        type='error'
      />
    );
  }

  if (bannerToShow === 'COMPANY_EXPIRING') {
    return (
      <ActionNeededBanner
        text={`Your company email expires in ${companyAccountDaysUntilExpiration} days. Reconnect now to keep sending emails with your company email address.`}
        onPress={handleConnectAccount}
        type='warning'
      />
    );
  }

  if (bannerToShow === 'USER_EXPIRING') {
    return (
      <ActionNeededBanner
        text={`Your email expires in ${userAccountDaysUntilExpiration} days. Reconnect now to keep sending emails with your email address.`}
        onPress={handleConnectAccount}
        type='warning'
      />
    );
  }

  return null;
};

const AccountGrantActionNeededBanner = () => {
  const {data} = useQuery<{viewer: UserModel}>(AccountGrantActionNeededBanner.query);

  if (!data) {
    return null;
  }

  const {viewer} = data;
  if (!viewer) {
    return null;
  }

  return <AccountGrantActionNeededContent viewer={viewer} />;
};

// --------------------------------------------------
// Data
// --------------------------------------------------
AccountGrantActionNeededBanner.query = gql`
  ${AccountGrant.accountGrantInfo.fragment}

  query AccountGrantActionNeededBanner {
    ${gql.query}
    viewer {
      id
      ...AccountGrant_accountGrantInfo
    }
  }
`;

export default AccountGrantActionNeededBanner;
