// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Icon, MultiDropdownInput, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {Form} from '@supermove/hooks';
import {AccountGrant, EmailTemplate, OrganizationModel, UserModel} from '@supermove/models';
import {AccountGrantInfoType} from '@supermove/models/src/AccountGrant';
import {colors, Typography} from '@supermove/styles';

// App
import DropdownInput from '@shared/design/components/DropdownInput';
import FieldInput from '@shared/design/components/Field/FieldInput';
import TextTooltip from '@shared/design/components/TextTooltip';
import {EmailRecipientOptionType} from '@shared/modules/Email/forms/EmailForm';
import EmailTemplateRecipientForm from '@shared/modules/Email/forms/EmailTemplateRecipientForm';
import {WrappedEmailFormType} from '@shared/modules/Email/hooks/useSendEmailForProjectMutation';

const Container = Styled.View`
`;

const Row = Styled.View`
  flex-direction: row;
  align-items: center;
`;

const Label = Styled.Text`
  ${Typography.Body3}
  color: ${colors.gray.primary};
`;

const Section = Styled.View<{sectionIndex: number}>`
  z-index: ${({sectionIndex}) => 100 - sectionIndex};
`;

const Button = Styled.ButtonV2`
`;

const CcButtonText = Styled.Text`
  ${Typography.Label2}
  color: ${colors.blue.interactive};
`;

const OptionText = Styled.Text`
  ${Typography.Body3}
  color: ${({
    // @ts-expect-error TS(2339): Property 'color' does not exist on type 'ThemeProp... Remove this comment to see the full error message
    color,
  }) => color};
`;

const OptionAddText = Styled.Text`
  ${Typography.Label2}
  color: ${colors.blue.interactive};
`;

const ErrorText = Styled.Text`
  ${Typography.Body3}
  color: ${colors.red.warning};
`;

const FromContainer = Styled.View`
`;

const getEmailRecipientForm = (email: string) => {
  return EmailTemplateRecipientForm.new({email});
};

const getIsNewCustomOption = ({
  option,
  searchInputValue,
}: {
  option: EmailRecipientOptionType;
  searchInputValue: string;
}) => {
  return searchInputValue === option.label && searchInputValue === option.value;
};

const getUpdatedOptions = ({
  recipientOptions,
  searchInputValue,
}: {
  recipientOptions: EmailRecipientOptionType[];
  searchInputValue: string;
}) => {
  const searchInputValueFirstChar = searchInputValue[0];
  const isSearchingForVariable = searchInputValueFirstChar === '{';
  const hasExactSearchMatch = !!_.find(
    recipientOptions,
    (option) => _.toLower(option.label) === _.toLower(searchInputValue),
  );
  if (!searchInputValue || isSearchingForVariable || hasExactSearchMatch) {
    return recipientOptions;
  }
  const customRecipientOption = {label: searchInputValue, value: searchInputValue};
  return [customRecipientOption, ...recipientOptions];
};

const getEmailWithRole = ({
  role,
  emailRecipientOptions,
}: {
  role: string;
  emailRecipientOptions: EmailRecipientOptionType[];
}) => {
  const emailRecipientWithRole = _.find(
    emailRecipientOptions,
    (recipient) => recipient.value === role,
  );
  return emailRecipientWithRole?.email ?? '';
};

const getIsRole = (value: string) => {
  return !!_.get(EmailTemplate.RECIPIENT_VALUES, value);
};

const handleUpdateRecipients = ({
  values,
  recipientOptions,
  form,
  field,
  recipientFormsField,
}: {
  values: string[];
  recipientOptions: EmailRecipientOptionType[];
  form: Form<WrappedEmailFormType>;
  field: string;
  recipientFormsField: string;
}) => {
  const emailRecipientOptions = _.get(form.values, `${field}.emailRecipientOptions`);
  const recipientForms = _.flatten(
    values.map((value) => {
      const isAddingNewRecipient = !_.find(
        recipientOptions,
        (option) => _.toLower(value) === _.toLower(option.value),
      );
      const emailString = getIsRole(value)
        ? getEmailWithRole({role: value, emailRecipientOptions})
        : value;
      const emails = emailString.split(',');

      if (isAddingNewRecipient) {
        form.setFieldValue(`${field}.emailRecipientOptions`, [
          ...emailRecipientOptions,
          {label: value, value},
        ]);
      }
      return emails.map((email) => getEmailRecipientForm(email));
    }),
  );
  form.setFieldValue(`${field}.${recipientFormsField}`, recipientForms);
};

const RecipientOption = ({
  searchInputValue,
  option,
  disabled,
  children,
}: {
  searchInputValue: string;
  option: EmailRecipientOptionType;
  disabled: boolean;
  children: React.ReactNode;
}) => {
  return (
    <Row>
      {getIsNewCustomOption({option, searchInputValue}) && (
        <React.Fragment>
          <Icon source={Icon.Plus} size={10} color={colors.blue.interactive} />
          <Space width={4} />
          <OptionAddText>Add</OptionAddText>
          <Space width={8} />
        </React.Fragment>
      )}
      <OptionText color={disabled ? colors.gray.tertiary : colors.gray.primary}>
        {children}
      </OptionText>
    </Row>
  );
};

const RecipientsField = ({
  form,
  field,
  recipientType,
  recipientOptions,
}: {
  form: Form<WrappedEmailFormType>;
  field: string;
  recipientType: string;
  recipientOptions: EmailRecipientOptionType[];
}) => {
  const searchInputValue = _.get(form.values, `${field}.searchInputValue`);
  const recipientFormsField = `${recipientType}EmailRecipientForms`;
  const recipientValuesField = `${recipientType}EmailRecipientValues`;
  const recipientErrors = _.get(form.errors, `${field}.${recipientFormsField}`);

  return (
    <React.Fragment>
      <FieldInput
        {...form}
        component={MultiDropdownInput}
        name={`${field}.${recipientValuesField}`}
        isResponsive
        input={{
          options: getUpdatedOptions({recipientOptions, searchInputValue}),
          placeholder: 'Enter recipients',
          setFieldValue: form.setFieldValue,
          isSearchable: true,
          isOptionDisabled: (option: EmailRecipientOptionType) => option.disabled,
          onChangeValue: (values: string[]) => {
            handleUpdateRecipients({values, recipientOptions, form, field, recipientFormsField});
          },
          onInputChange: (value: string) => {
            form.setFieldValue(`${field}.searchInputValue`, value);
          },
          components: {
            Option: (props: any) => {
              return (
                <MultiDropdownInput.Option
                  {...props}
                  optionComponentProps={{searchInputValue}}
                  OptionComponent={RecipientOption}
                />
              );
            },
          },
          style: {
            width: '100%',
            borderColor: recipientErrors ? colors.red.warning : colors.gray.tertiary,
          },
        }}
        style={{
          width: '100%',
        }}
      />
      {!!recipientErrors && (
        <React.Fragment>
          <Space height={4} />
          {/* TODO(dan) Once backend is updated, show message from backend for input */}
          <ErrorText>
            {_.get(
              // @ts-expect-error the error type doesn't work with the _.get function
              _.filter(recipientErrors, (error) => !!error),
              '0.email',
              'Invalid email found.',
            )}
          </ErrorText>{' '}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

const getFromAccountTooltipText = (accountGrantInfo: AccountGrantInfoType) => {
  if (accountGrantInfo.hasExpiredCompanyAccount) {
    if (accountGrantInfo.hasEditPermissions) {
      return 'Company email disconnected. Reconnect to send from the company account.';
    }
    return 'Company email disconnected. Contact your admin to reconnect and send from the company account.';
  }
  if (accountGrantInfo.hasExpiredUserAccount) {
    return 'Your email disconnected. Reconnect to send with your own address.';
  }
};

const DEFAULT_VALUE = 'DEFAULT';
const FromField = ({
  form,
  field,
  user,
}: {
  form: Form<WrappedEmailFormType>;
  field: string;
  user: UserModel;
}) => {
  const accountGrantOptions = _.get(form.values, `${field}.accountGrantOptions`);
  const defaultEmailLabel = user.supermoveEmailAddressDisplayName;
  // If there are account grants show those, if not, show the default supermove email that will be used
  const options = accountGrantOptions.length
    ? accountGrantOptions
    : [{label: defaultEmailLabel, value: DEFAULT_VALUE}];
  // value needs to not be set even as `value=undefined`,
  // or it becomes a controlled component and doesn't read from the form
  const inputProps = options[0]?.value === DEFAULT_VALUE ? {value: DEFAULT_VALUE} : {};
  const accountGrantInfo = AccountGrant.accountGrantInfo(user);
  const fromAccountTooltipText = getFromAccountTooltipText(accountGrantInfo);
  return (
    <FromContainer>
      <FieldInput
        {...form}
        label={'From'}
        LabelIconComponent={
          fromAccountTooltipText
            ? () => (
                <TextTooltip text={fromAccountTooltipText}>
                  <Icon source={Icon.InfoCircle} color={colors.gray.secondary} />
                </TextTooltip>
              )
            : undefined
        }
        name={`${field}.accountGrantId`}
        component={DropdownInput}
        input={{
          isPortaled: true,
          isDisabled: options.length <= 1, // If there aren't 2 or more options, disable the dropdown
          options,
          // Only set the field if it came from an account grant, not the default email
          setFieldValue: (name: string, newValue: string) =>
            newValue === DEFAULT_VALUE ? null : form.setFieldValue(name, newValue),
          style: {width: '100%'},
          ...inputProps,
        }}
        isResponsive
      />
      <Space height={16} />
    </FromContainer>
  );
};

const EmailRecipientsSection = ({
  field,
  form,
  organization,
  user,
}: {
  field: string;
  form: Form<WrappedEmailFormType>;
  organization: OrganizationModel;
  user: UserModel;
}) => {
  const {isShowingCc, isShowingBcc} = _.get(form.values, field);
  const recipientOptions = _.get(form.values, `${field}.emailRecipientOptions`);
  return (
    <Container>
      <Section sectionIndex={0}>
        {organization.features.isEnabledSelectFromAccount ? (
          <FromField form={form} field={field} user={user} />
        ) : null}
        <Row>
          <FieldInput.LabelText isRequired isResponsive>
            To
          </FieldInput.LabelText>
          <Space style={{flex: 1}} />
          {!isShowingCc && (
            <Button onPress={() => form.setFieldValue(`${field}.isShowingCc`, true)}>
              <CcButtonText>Cc</CcButtonText>
            </Button>
          )}
          {!isShowingBcc && (
            <React.Fragment>
              <Space width={12} />
              <Button onPress={() => form.setFieldValue(`${field}.isShowingBcc`, true)}>
                <CcButtonText>Bcc</CcButtonText>
              </Button>
            </React.Fragment>
          )}
        </Row>
        <Space height={2} />
        <RecipientsField
          form={form}
          field={field}
          recipientType={'to'}
          recipientOptions={recipientOptions}
        />
        <Space height={12} />
      </Section>
      {isShowingCc && (
        <Section sectionIndex={1}>
          <Label>Cc</Label>
          <Space height={2} />
          <RecipientsField
            form={form}
            field={field}
            recipientType={'cc'}
            recipientOptions={recipientOptions}
          />
          <Space height={12} />
        </Section>
      )}
      {isShowingBcc && (
        <Section sectionIndex={2}>
          <Label>Bcc</Label>
          <Space height={2} />
          <RecipientsField
            form={form}
            field={field}
            recipientType={'bcc'}
            recipientOptions={recipientOptions}
          />
          <Space height={12} />
        </Section>
      )}
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EmailRecipientsSection.fragment = gql`
  ${AccountGrant.accountGrantInfo.fragment}

  fragment EmailRecipientsSection on Organization {
    id
    features {
      isEnabledSelectFromAccount: isEnabled(feature: "SELECT_FROM_ACCOUNT")
    }
  }

  fragment EmailRecipientsSection_User on User {
    id
    supermoveEmailAddressDisplayName
    ...AccountGrant_accountGrantInfo
  }
`;

export default EmailRecipientsSection;
