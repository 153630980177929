// Libraries
import React from 'react';

// Supermove
import {Space} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {AccountGrant, UserModel} from '@supermove/models';
import {colors} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

import ErrorCallout from '@shared/design/components/Callout/ErrorCallout';
import Text from '@shared/design/components/Text';
import CreateAccountGrantForm from '@shared/modules/AccountGrant/forms/CreateAccountGrantForm';
import useConnectAccountGrant from 'modules/Organization/Settings/Communication/hooks/useConnectAccountGrant';

// App

interface EmailGrantExpiredBannerProps {
  viewer: UserModel;
  spacingAboveIfVisible?: number;
  spacingBelowIfVisible?: number;
}

const getExpiredInfo = (expiredStatusUpdatedAt: string) => {
  const dateExpired = Datetime.convertToDisplayDatetime(
    expiredStatusUpdatedAt,
    Datetime.DISPLAY_SHORT_DATE,
  );
  const timeExpired = Datetime.convertToDisplayDatetime(
    expiredStatusUpdatedAt,
    Datetime.DISPLAY_TIME,
  );
  return {dateExpired, timeExpired};
};

const getCompanyAccountExpiredText = (
  expiredCompanyAccountStatusUpdatedAt: string,
  hasEditPermissions: boolean,
) => {
  const {dateExpired, timeExpired} = getExpiredInfo(expiredCompanyAccountStatusUpdatedAt);
  return `The company email disconnected on ${dateExpired} at ${timeExpired}. ${hasEditPermissions ? `Reconnect the company email account to reply.` : 'Contact your admin to reconnect the email account to reply.'} `;
};

const getUserAccountExpiredText = (expiredCompanyAccountStatusUpdatedAt: string) => {
  const {dateExpired, timeExpired} = getExpiredInfo(expiredCompanyAccountStatusUpdatedAt);
  return `Your email disconnected on ${dateExpired} at ${timeExpired}. Reconnect to reply with your own address.`;
};

const ErrorBannerMessage = ({
  text,
  hasEditPermissions,
  connectAccountGrant,
  spacingAbove,
  spacingBelow,
}: {
  text: string;
  hasEditPermissions: boolean;
  connectAccountGrant: () => void;
  spacingAbove?: number;
  spacingBelow?: number;
}) => {
  return (
    <React.Fragment>
      {spacingAbove ? <Space height={spacingAbove} /> : null}
      <ErrorCallout>
        <Text.Body style={{color: colors.red.warning}}>
          {text}
          {hasEditPermissions ? (
            <React.Fragment>
              {' '}
              <Text.Link onPress={connectAccountGrant}>Click to reconnect</Text.Link>
            </React.Fragment>
          ) : (
            ''
          )}
        </Text.Body>
      </ErrorCallout>
      {spacingBelow ? <Space height={spacingBelow} /> : null}
    </React.Fragment>
  );
};

// The logic is:
// If there's an active user account, don't show anything because it will send from that user account.
// If no active user grant, then:
// Expired company grant (regardless of user grant expired) -> show company account expired banner. Note, link to reconnect only appears for admins
// Expired user grant, no expired company grant -> show user account expired banner. Note, wording is different if there's an active company account.
// No expired grants -> show nothing
const EmailGrantExpiredBanner = ({
  viewer,
  spacingAboveIfVisible,
  spacingBelowIfVisible,
}: EmailGrantExpiredBannerProps) => {
  const {
    hasEditPermissions,
    hasActiveCompanyAccount,
    hasActiveUserAccount,
    hasExpiredCompanyAccount,
    hasExpiredUserAccount,
    expiredCompanyAccountStatusUpdatedAt,
    expiredUserAccountStatusUpdatedAt,
  } = AccountGrant.accountGrantInfo(viewer);
  const isCompanyAccount = hasExpiredCompanyAccount || hasExpiredUserAccount;
  const connectionRedirectPath = isCompanyAccount
    ? '/settings/communication/general'
    : '/account/communication/email';

  const createAccountGrantForm = CreateAccountGrantForm.new({
    kind: isCompanyAccount ? 'ORGANIZATION' : 'USER',
    provider: 'NYLAS',
    organizationId: viewer.viewingOrganization.id,
    userId: isCompanyAccount ? undefined : viewer.id,
  });
  const {connectAccountGrant} = useConnectAccountGrant({
    createAccountGrantForm,
    onSuccess: () => {},
    redirectPath: connectionRedirectPath,
  });

  if (hasActiveUserAccount) {
    return null;
  }
  if (hasExpiredCompanyAccount) {
    return (
      <ErrorBannerMessage
        text={getCompanyAccountExpiredText(
          expiredCompanyAccountStatusUpdatedAt ?? '',
          hasEditPermissions,
        )}
        hasEditPermissions={hasEditPermissions}
        connectAccountGrant={connectAccountGrant}
        spacingAbove={spacingAboveIfVisible}
        spacingBelow={spacingBelowIfVisible}
      />
    );
  }
  if (hasExpiredUserAccount) {
    return (
      <ErrorBannerMessage
        text={getUserAccountExpiredText(expiredUserAccountStatusUpdatedAt ?? '')}
        hasEditPermissions
        connectAccountGrant={connectAccountGrant}
        spacingAbove={spacingAboveIfVisible}
        spacingBelow={spacingBelowIfVisible}
      />
    );
  }

  return null;
};

// --------------------------------------------------
// Data
// --------------------------------------------------
EmailGrantExpiredBanner.fragment = gql`
  ${AccountGrant.accountGrantInfo.fragment}

  fragment EmailGrantExpiredBanner on User {
    id
    ...AccountGrant_accountGrantInfo
  }
`;

export default EmailGrantExpiredBanner;
