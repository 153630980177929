// Libraries
import React from 'react';

// Supermove
import {Link, Loading, Styled} from '@supermove/components';
import {gql, useQuery} from '@supermove/graphql';
import {useNavigationDOM} from '@supermove/hooks';
import {ProjectModel, UserModel} from '@supermove/models';
import {Typography} from '@supermove/styles';
import {Datetime, Phone} from '@supermove/utils';

// App
import Table from '@shared/design/components/Table';
import PageLoadingIndicator from '@shared/modules/App/components/PageLoadingIndicator';
import CompanySettingsPage from 'modules/Organization/Settings/Company/components/CompanySettingsPage';

const LinkText = Styled.Text`
  ${Typography.Link}
`;

const Row = Styled.View<{index: number}>`
  z-index: ${({index}) => 100 - index};
`;

const OrganizationSettingsCompanyLeadProvidersLeadPage = () => {
  const {params} = useNavigationDOM();
  const {loading, data} = useQuery(OrganizationSettingsCompanyLeadProvidersLeadPage.query, {
    fetchPolicy: 'cache-and-network',
    variables: {
      inboundWebhookEndpointUuid: params.uuid,
    },
  });

  return (
    <CompanySettingsPage
      title={`Lead Providers / ${data?.leadProviderEndpoint.inboundWebhookEndpoint.name}`}
      description={'View and access leads from this provider.'}
    >
      <Loading loading={loading} as={PageLoadingIndicator}>
        {() => <Content viewer={data.viewer} projects={data.leadProviderEndpoint.projects} />}
      </Loading>
    </CompanySettingsPage>
  );
};

const Content = ({viewer, projects}: {viewer: UserModel; projects: ProjectModel[]}) => {
  const {viewingOrganization, role} = viewer;

  const columnDefinitions = [
    {
      headerLabel: 'Project',
      flex: 3,
      cellComponent: (project: ProjectModel) => (
        <Link to={`/projects/${project.uuid}`}>
          <LinkText>{project.identifier}</LinkText>
        </Link>
      ),
    },
    {
      headerLabel: 'Client',
      flex: 4,
      cellText: (project: ProjectModel) => project.client.name,
    },
    {
      headerLabel: 'Phone Number',
      flex: 2,
      cellText: (project: ProjectModel) => Phone.display(project.client.primaryContact.phoneNumber),
    },
    {
      headerLabel: 'Email',
      flex: 4,
      cellText: (project: ProjectModel) => project.client.primaryContact.email,
    },
    {
      headerLabel: 'Created At',
      flex: 2,
      cellText: (project: ProjectModel) =>
        Datetime.toDisplayTime(
          Datetime.fromDatetime(project.createdAt),
          Datetime.DISPLAY_DATETIME,
          viewingOrganization.timezone,
        ),
    },
  ];

  return (
    <Table
      items={projects}
      itemKey={'id'}
      columnDefinitions={columnDefinitions}
      hasBorder
      emptyStateText={'No lead providers added yet.'}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
OrganizationSettingsCompanyLeadProvidersLeadPage.query = gql`
  query OrganizationSettingsCompanyLeadProvidersLeadPage($inboundWebhookEndpointUuid: String!){
    ${gql.query}
    viewer {
      id
      viewingOrganization {
        id
        timezone
      }
    }
    leadProviderEndpoint: leadProviderEndpoint(inboundWebhookEndpointUuid: $inboundWebhookEndpointUuid) {
      id
      inboundWebhookEndpoint {
        id
        name
      }
      projects {
        id
        uuid
        createdAt
        identifier
        client {
          id
          name
          primaryContact {
            id
            phoneNumber
            email
          }
        } 
      }
    }
  }
`;

export default OrganizationSettingsCompanyLeadProvidersLeadPage;
