// Libraries
import React from 'react';

// Supermove
import {Icon, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {useDrawer, useResponsive} from '@supermove/hooks';
import {Task} from '@supermove/models';
import {Typography, colors} from '@supermove/styles';

// App
import TextTooltip from '@shared/design/components/TextTooltip';
import UserAvatar from '@shared/modules/User/components/UserAvatar';
import EditTaskDrawer from 'modules/TaskManagement/Tasks/components/EditTaskDrawer';
import TaskDueIcon from 'modules/TaskManagement/Tasks/components/TaskDueIcon';
import TaskIsCompletedCheckbox from 'modules/TaskManagement/Tasks/components/TaskIsCompletedCheckbox';
import useToggleTaskIsCompleted from 'modules/TaskManagement/Tasks/hooks/useToggleTaskIsCompleted';

const TaskCard = Styled.ButtonV2`
  flex-direction: row;
  align-items: center;
  border-radius: 4px;
  background-color: ${colors.gray.background};
  padding-vertical: 8px;
`;

const CardBodyContainer = Styled.View`
  flex: 1;
`;

const CardTitle = Styled.Text`
  ${Typography.Responsive.Label}
`;

const CardSubTitle = Styled.Text`
  ${Typography.Responsive.Micro}
`;

const TeamAvatarContainer = Styled.View`
  width: 24px;
  height: 20px;
  align-items: center;
  justify-content: center;
  background-color: ${colors.gray.border}
  border-radius: 3px;
`;

const CardBody = ({task}: any) => {
  const responsive = useResponsive();
  return (
    <CardBodyContainer>
      <CardTitle responsive={responsive}>{task.name}</CardTitle>
      <Space height={4} />
      <CardSubTitle responsive={responsive}>
        <TaskDueIcon task={task} />
        {task.dueAt ? Task.getDisplayDate(task) : 'No due date'}
      </CardSubTitle>
    </CardBodyContainer>
  );
};

const TeamAvatar = ({team}: any) => {
  return (
    <TextTooltip isEnabledMobileToast={false} text={team.name} placement={'left'}>
      <TeamAvatarContainer>
        <Icon source={Icon.Users} size={12} color={colors.gray.tertiary} />
      </TeamAvatarContainer>
    </TextTooltip>
  );
};

const TaskCardContent = ({
  isVisible,
  task,
  editTaskDrawer,
  handleCompleteTask,
  submitting,
}: any) => {
  return (
    <React.Fragment>
      {isVisible && (
        <TaskCard key={task.isCompleted} onPress={editTaskDrawer.handleOpen}>
          <TaskIsCompletedCheckbox
            task={task}
            onPress={handleCompleteTask}
            isDisabled={submitting}
            style={{padding: 12}}
          />
          <CardBody task={task} />
          <Space width={12} />
          {task.assignedToUser && (
            <UserAvatar user={task.assignedToUser} tooltipPlacement={'left'} />
          )}
          {task.assignedToTeam && <TeamAvatar team={task.assignedToTeam} />}
          <Space width={12} />
        </TaskCard>
      )}
    </React.Fragment>
  );
};

type OwnJobProjectTaskCardProps = {
  task: any;
  isVisible?: boolean;
};

// @ts-expect-error TS(2456): Type alias 'JobProjectTaskCardProps' circularly re... Remove this comment to see the full error message
type JobProjectTaskCardProps = OwnJobProjectTaskCardProps & typeof JobProjectTaskCard.defaultProps;

// @ts-expect-error TS(7022): 'JobProjectTaskCard' implicitly has type 'any' bec... Remove this comment to see the full error message
const JobProjectTaskCard = ({task, refetch, isVisible}: JobProjectTaskCardProps) => {
  const editTaskDrawer = useDrawer({
    name: 'Edit Task Drawer',
    enableTracking: true,
  });
  const {handleSubmit, submitting} = useToggleTaskIsCompleted({task, onSuccess: refetch});

  return (
    <React.Fragment>
      <TaskCardContent
        key={task.isCompleted}
        isVisible={isVisible}
        task={task}
        editTaskDrawer={editTaskDrawer}
        handleCompleteTask={handleSubmit}
        submitting={submitting}
      />
      <EditTaskDrawer
        isOpen={editTaskDrawer.isOpen}
        handleClose={editTaskDrawer.handleClose}
        task={task}
        refetch={refetch}
      />
    </React.Fragment>
  );
};

JobProjectTaskCard.defaultProps = {
  isVisible: true,
};

// --------------------------------------------------
// Data
// --------------------------------------------------
JobProjectTaskCard.fragment = gql`
  ${useToggleTaskIsCompleted.fragment}
  ${EditTaskDrawer.fragment}
  ${Task.getDisplayDate.fragment}
  ${TaskDueIcon.fragment}
  ${TaskIsCompletedCheckbox.fragment}
  ${UserAvatar.fragment}
  fragment JobProjectTaskCard on Task {
    id
    dueAt
    isCompleted
    name
    assignedToUser {
      id
      ...UserAvatar
    }
    assignedToTeam {
      id
      name
    }
    ...useToggleTaskIsCompleted
    ...EditTaskDrawer
    ...Task_getDisplayDate
    ...TaskDueIcon
    ...TaskIsCompletedCheckbox
  }
`;

export default JobProjectTaskCard;
