// Libraries
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {colors, fontWeight, Typography} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App
import UserAvatar from '@shared/modules/User/components/UserAvatar';

const Container = Styled.View`
  flex: 1;
`;

const Title = Styled.Text`
  ${Typography.Micro}
  ${fontWeight(700)}
  color: ${colors.gray.primary};
`;

const Description = Styled.Text`
  ${Typography.Micro}
`;

const Row = Styled.View`
  flex-direction: row;
`;

const TaskEventsList = ({task}: any) => {
  return (
    <React.Fragment>
      {task.jobEvents.map((jobEvent: any) => {
        const eventDate = Datetime.convertToDisplayDatetime(jobEvent.timestamp, 'ddd M/D, h:mm A');
        return (
          <React.Fragment key={jobEvent.id}>
            <Space height={16} />
            <Row>
              <UserAvatar user={jobEvent.creator} />
              <Space width={8} />
              <Container>
                <Title>{`${eventDate} - ${jobEvent.name}`}</Title>
                <Description>{jobEvent.description}</Description>
              </Container>
            </Row>
          </React.Fragment>
        );
      })}
    </React.Fragment>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
TaskEventsList.fragment = gql`
  ${UserAvatar.fragment}

  fragment TaskEventsList on Task {
    id
    jobEvents {
      id
      timestamp
      name
      description
      creator {
        id
        ...UserAvatar
      }
    }
  }
`;

export default TaskEventsList;
