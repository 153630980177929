// Libraries
import React from 'react';

// Supermove
import {Link, Loading, Styled} from '@supermove/components';
import {gql, useQuery} from '@supermove/graphql';
import {useToast} from '@supermove/hooks';
import {UserModel} from '@supermove/models';
import {Typography, colors} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App
import Badge from '@shared/design/components/Badge';
import Switch from '@shared/design/components/Switch';
import Table from '@shared/design/components/Table';
import Toast from '@shared/design/components/Toast';
import PageLoadingIndicator from '@shared/modules/App/components/PageLoadingIndicator';
import CompanySettingsPage from 'modules/Organization/Settings/Company/components/CompanySettingsPage';

const LinkButton = Styled.ButtonV2`
`;

const LinkText = Styled.Text`
  ${Typography.Link}
`;

const OrganizationSettingsCompanyLeadProvidersPage = () => {
  const {loading, data, refetch} = useQuery(OrganizationSettingsCompanyLeadProvidersPage.query, {
    fetchPolicy: 'cache-and-network',
  });

  return (
    <CompanySettingsPage
      title={'Lead Providers'}
      description={'Create and manage your lead providers.'}
    >
      <Loading loading={loading} as={PageLoadingIndicator}>
        {() => (
          <Content
            viewer={data?.viewer}
            endpoints={data.viewer.viewingOrganization.leadProviderEndpoints}
            refetch={refetch}
          />
        )}
      </Loading>
    </CompanySettingsPage>
  );
};

const Content = ({
  viewer,
  endpoints,
  refetch,
}: {
  viewer: UserModel;
  endpoints: LeadProviderEndpoint[];
  refetch: () => void;
}) => {
  const copiedToast = useToast({
    ToastComponent: Toast,
    message: `Copied to clipboard.`,
  });

  const {viewingOrganization, role} = viewer;
  const columnDefinitions = [
    {
      headerLabel: ' ',
      flex: 0.5,
      cellComponent: ({inboundWebhookEndpoint}: LeadProviderEndpoint) => {
        return (
          <Switch
            isOn={inboundWebhookEndpoint.status === 'active'}
            onChange={() => console.log('TODO')}
          />
        );
      },
    },
    {
      headerLabel: 'Status',
      flex: 1,
      cellComponent: ({inboundWebhookEndpoint}: LeadProviderEndpoint) => (
        <Badge
          isResponsive
          label={
            inboundWebhookEndpoint.status[0].toUpperCase() +
            inboundWebhookEndpoint.status.substring(1)
          }
          color={
            inboundWebhookEndpoint.status === 'active' ? colors.green.status : colors.gray.secondary
          }
          style={{marginVertical: 2}}
        />
      ),
    },
    {
      headerLabel: 'Name',
      flex: 3,
      cellText: ({inboundWebhookEndpoint}: LeadProviderEndpoint) => inboundWebhookEndpoint.name,
      secondary: {
        headerLabel: 'Description',
        cellText: ({inboundWebhookEndpoint}: LeadProviderEndpoint) => {
          return inboundWebhookEndpoint.description;
        },
      },
    },
    {
      headerLabel: 'Type',
      flex: 1,
      cellText: (endpoint: LeadProviderEndpoint) => endpoint.type,
    },
    {
      headerLabel: 'Webhook URL',
      flex: 3,
      cellComponent: ({inboundWebhookEndpoint}: LeadProviderEndpoint) => (
        <LinkButton
          onPress={() => {
            navigator.clipboard.writeText(inboundWebhookEndpoint.url);
            copiedToast.handleToast();
          }}
        >
          <LinkText>{inboundWebhookEndpoint.url}</LinkText>
        </LinkButton>
      ),
      cellStyle: {overflow: 'hidden'},
    },
    {
      headerLabel: 'Leads',
      flex: 1,
      cellStyle: {alignItems: 'flex-end'},
      cellComponent: ({inboundWebhookEndpoint}: LeadProviderEndpoint) => (
        <Link to={`/settings/company/lead-providers/${inboundWebhookEndpoint.uuid}`}>
          <LinkText>{inboundWebhookEndpoint.summary.requests}</LinkText>
        </Link>
      ),
    },
    {
      headerLabel: 'Last Request',
      flex: 2,
      cellText: ({inboundWebhookEndpoint}: LeadProviderEndpoint) =>
        Datetime.toDisplayTime(
          Datetime.fromDatetime(inboundWebhookEndpoint.summary.latestRequestAt),
          Datetime.DISPLAY_DATETIME,
          viewingOrganization.timezone,
        ),
    },
    {
      headerLabel: 'Created At',
      flex: 2,
      cellText: ({inboundWebhookEndpoint}: LeadProviderEndpoint) =>
        Datetime.toDisplayTime(
          Datetime.fromDatetime(inboundWebhookEndpoint.createdAt),
          Datetime.DISPLAY_DATETIME,
          viewingOrganization.timezone,
        ),
      secondary: {
        cellText: ({inboundWebhookEndpoint}: LeadProviderEndpoint) =>
          inboundWebhookEndpoint.createdByUser.fullName,
      },
    },
  ];

  return (
    <Table
      items={endpoints}
      itemKey={'name'}
      columnDefinitions={columnDefinitions}
      hasBorder
      emptyStateText={'No lead providers added yet.'}
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
OrganizationSettingsCompanyLeadProvidersPage.query = gql`
    query OrganizationSettingsCompanyLeadProvidersPage {
        ${gql.query}
        viewer {
            id
            viewingOrganization {
                id
                timezone
                leadProviderEndpoints {
                    id
                    type
                    inboundWebhookEndpoint {
                        id
                        uuid
                        createdAt
                        name
                        description
                        status
                        url
                        summary {
                            requests
                            latestRequestAt
                        }
                        createdByUser {
                          id
                          fullName 
                        }
                    } 
                }
            }
        }
    }
`;

interface LeadProviderEndpoint {
  id: string;
  type: string;
  inboundWebhookEndpoint: {
    createdAt: string;
    uuid: string;
    name: string;
    description: string;
    status: 'active' | 'inactive';
    url: string;
    summary: {
      requests: number;
      latestRequestAt: string;
    };
    createdByUser: {
      id: string;
      fullName: string;
    };
  };
}

export default OrganizationSettingsCompanyLeadProvidersPage;
