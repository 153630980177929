// Library
import React from 'react';

// Supermove
import {Popover, Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {ResponsiveType} from '@supermove/hooks';
import {Conversation, ConversationModel} from '@supermove/models';
import {colors} from '@supermove/styles';
import {Datetime} from '@supermove/utils';

// App
import Text from '@shared/design/components/Text';
import TextTooltip from '@shared/design/components/TextTooltip';
import ConversationForm from '@shared/modules/Communication/forms/ConversationForm';
import ConversationItemStatus from 'modules/Communication/CommunicationInbox/ConversationList/components/ConversationItemStatus';
import CommunicationInboxConversationPopoverOptions from 'modules/Communication/CommunicationInbox/ProjectConversation/components/CommunicationInboxConversationPopoverOptions';

const Container = Styled.View`
  flex-direction: row;
  align-items: center;
  margin-left: -16px;
`;

const StatusFromDateActions = Styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex: 1;
`;

const StatusFromDate = Styled.View`
  flex-direction: row;
  align-items: center;
  flex-shrink: 1;
`;

const Actions = Styled.View`
  flex-direction: row;
  align-items: center;
  flex-shrink: 1;
`;

const NeedsFollowUpDotPlaceholder = Styled.View`
  margin-right: 16px;
`;

const dotSize = 6;
const NeedsFollowUpDot = Styled.View`
  width: ${dotSize}px;
  height: ${dotSize}px;
  border-radius: ${dotSize / 2}px;
  background-color: ${colors.red.warning};
  margin-horizontal: ${(16 - dotSize) / 2}px
`;

interface CommunicationInboxTopLineProps {
  responsive: ResponsiveType;
  conversation: ConversationModel;
  fromName?: string;
  sentAt?: string;
}

const CommunicationInboxTopLine = ({
  responsive,
  conversation,
  fromName,
  sentAt,
}: CommunicationInboxTopLineProps) => {
  return (
    <Container>
      {conversation.status === Conversation.ConversationStatus.OPEN &&
      conversation.organization.features.isEnabledConversationFollowUpDot ? (
        <TextTooltip text={'Needs follow up'}>
          <NeedsFollowUpDot />
        </TextTooltip>
      ) : (
        <NeedsFollowUpDotPlaceholder />
      )}
      <StatusFromDateActions>
        <StatusFromDate>
          <ConversationItemStatus responsive={responsive} conversation={conversation} />
          <Space width={4} />
          {fromName ? (
            <Text.Micro numberOfLines={1} style={{flexShrink: 1}}>
              {fromName},
            </Text.Micro>
          ) : null}
          <Space width={4} />
          <Text.Micro numberOfLines={1} style={{flexShrink: 0}}>
            {sentAt ? Datetime.convertToDisplayDatetime(sentAt) : null}
          </Text.Micro>
        </StatusFromDate>
        <Space width={8} />
        <Actions>
          <CommunicationInboxConversationPopoverOptions
            responsive={responsive}
            projectUuid={conversation.project.uuid}
            position={Popover.Positions.RightStart}
          />
        </Actions>
      </StatusFromDateActions>
    </Container>
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
CommunicationInboxTopLine.fragment = gql`
  ${Conversation.getStatusInfo.fragment}
  ${ConversationForm.edit.fragment}

  fragment CommunicationInboxTopLine on Conversation {
    id
    status
    organization {
      id
      features {
        isEnabledConversationFollowUpDot: isEnabled(feature: "CONVERSATION_FOLLOW_UP_DOT")
      }
    }
    ...Conversation_getStatusInfo
    ...ConversationForm_edit
    project {
      id
      uuid
    }
  }
`;

export default CommunicationInboxTopLine;
