// Libraries
import _ from 'lodash';
import React from 'react';

// Supermove
import {Space, Styled} from '@supermove/components';
import {gql} from '@supermove/graphql';
import {
  useResponsive,
  ResponsiveType,
  MutationError,
  ModalType,
  useScrollView,
} from '@supermove/hooks';
import {UserModel} from '@supermove/models';
import {colors} from '@supermove/styles';

// App
import ProgressModal from '@shared/design/components/ProgressModal';
import Text from '@shared/design/components/Text';
import useProgress from '@shared/modules/App/hooks/useProgress';
import InviteOfficeAppUserStep from '@shared/modules/User/enums/InviteOfficeAppUserStep';
import InviteUserForm from '@shared/modules/User/forms/InviteUserForm';
import useInviteUserToOrganizationMutation from '@shared/modules/User/hooks/useInviteUserToOrganizationMutation';
import InviteOfficeAppUserInfoFields from 'modules/Organization/Settings/Staff/components/InviteOfficeAppUserInfoFields';
import InviteOfficeAppUserModalFooter from 'modules/Organization/Settings/Staff/components/InviteOfficeAppUserModalFooter';
import InviteOfficeAppUserReviewStep from 'modules/Organization/Settings/Staff/components/InviteOfficeAppUserReviewStep';

const MobilePaddingContainer = Styled.View<{responsive: ResponsiveType}>`
  padding-horizontal: ${({responsive}) => (responsive.desktop ? 0 : 16)}px;
`;

// TODO(Hammad) figure out the types
const StepFields = ({
  stepKind,
  form,
  field,
  setCurrentStepKind,
  viewer,
}: {
  stepKind: string;
  form: any;
  field: string;
  setCurrentStepKind: any;
  viewer: UserModel;
}) => {
  const responsive = useResponsive();
  switch (stepKind) {
    case InviteOfficeAppUserStep.USER_INFO:
      return (
        <MobilePaddingContainer responsive={responsive}>
          <InviteOfficeAppUserInfoFields form={form} field={field} viewer={viewer} />
        </MobilePaddingContainer>
      );
    case InviteOfficeAppUserStep.REVIEW:
      return (
        <InviteOfficeAppUserReviewStep
          form={form}
          field={field}
          setCurrentStepKind={setCurrentStepKind}
          viewer={viewer}
        />
      );
    default:
      return null;
  }
};

const InviteOfficeAppUserModalBody = ({
  form,
  field,
  currentStep,
  currentStepKind,
  setCurrentStepKind,
  viewer,
}: {
  form: any;
  field: string;
  currentStep: any;
  currentStepKind: string;
  setCurrentStepKind: any;
  viewer: UserModel;
}) => {
  const responsive = useResponsive();

  return (
    <React.Fragment>
      <MobilePaddingContainer responsive={responsive}>
        <Text.Heading2>{currentStep.title}</Text.Heading2>
        <Space height={8} />
        <Text.Body style={{color: colors.gray.secondary}}>{currentStep.description}</Text.Body>
      </MobilePaddingContainer>
      <Space height={16} />
      <StepFields
        stepKind={currentStepKind}
        form={form}
        field={field}
        setCurrentStepKind={setCurrentStepKind}
        viewer={viewer}
      />
      {responsive.desktop && <Space height={24} />}
    </React.Fragment>
  );
};

const InviteOfficeAppUserModal = ({
  isOpen,
  handleClose,
  refetch,
  viewer,
  inviteOfficeUserSuccessModal,
}: {
  isOpen: boolean;
  handleClose: () => void;
  refetch: () => void;
  viewer: UserModel;
  inviteOfficeUserSuccessModal: ModalType;
}) => {
  const inviteUserModalScrollView = useScrollView();

  const inviteUserForm = InviteUserForm.new({
    organizationId: Number(viewer.viewingOrganization.id),
  });

  const {form, submitting, handleSubmit} = useInviteUserToOrganizationMutation({
    inviteUserForm,
    onSuccess: () => {
      handleClose();
      inviteOfficeUserSuccessModal.handleOpen();
      refetch();
    },
    onError: (errors: MutationError[]) => {
      console.log({errors});
      inviteUserModalScrollView.handleScrollToTop({animated: true});
    },
  });

  const steps = InviteOfficeAppUserStep.STEPS.map((step) => ({
    kind: step.kind,
  }));

  const {
    currentStepKind,
    setCurrentStepKind,
    isViewingFirstStep,
    previousStepKind,
    nextStepKind,
    completedSteps,
    setCompletedSteps,
    allStepKinds,
    nextStepToComplete,
    currentStepIndex,
  } = useProgress({
    steps,
    getPreviousStepKind: InviteOfficeAppUserStep.getPreviousStepKind,
    getNextStepKind: InviteOfficeAppUserStep.getNextStepKind,
  });

  const currentStep = InviteOfficeAppUserStep.getStep(currentStepKind);

  const isReviewingSteps = _.isEqual(
    _.sortBy(allStepKinds.filter((stepKind) => stepKind !== 'REVIEW')),
    _.sortBy(completedSteps),
  );

  return (
    <ProgressModal
      scrollRef={inviteUserModalScrollView.ref}
      isOpen={isOpen}
      handleClose={handleClose}
      showConfirmQuit={form.dirty}
      steps={steps}
      completedSteps={completedSteps}
      isViewingFirstStep={isViewingFirstStep}
      currentStepKind={currentStepKind}
      getStepTitle={(kind) => InviteOfficeAppUserStep.getStep(kind).title}
      setCurrentStepKind={setCurrentStepKind}
      nextStepToComplete={nextStepToComplete}
      currentStepIndex={currentStepIndex}
      title={'Invite Team Member'}
      handleBack={
        isViewingFirstStep
          ? null
          : () => {
              previousStepKind && setCurrentStepKind(previousStepKind);
            }
      }
      BodyElement={
        <InviteOfficeAppUserModalBody
          form={form}
          field={'inviteUserForm'}
          currentStep={currentStep}
          currentStepKind={currentStepKind}
          setCurrentStepKind={setCurrentStepKind}
          viewer={viewer}
        />
      }
      FooterElement={
        <InviteOfficeAppUserModalFooter
          isViewingFirstStep={isViewingFirstStep}
          previousStepKind={previousStepKind}
          nextStepKind={nextStepKind}
          currentStepKind={currentStepKind}
          setCurrentStepKind={setCurrentStepKind}
          setCompletedSteps={setCompletedSteps}
          form={form}
          isReviewingSteps={isReviewingSteps}
          handleSubmit={handleSubmit}
          submitting={submitting}
        />
      }
    />
  );
};

// --------------------------------------------------
// Data
// --------------------------------------------------
InviteOfficeAppUserModal.fragment = gql`
  fragment InviteOfficeAppUserModal on User {
    id
    viewingOrganization {
      id
    }
  }
`;

export default InviteOfficeAppUserModal;
